:root {

  --white: #FFF;

  --gray-50: #EEEEF2;
  --gray-100: #D1D2DC;
  --gray-200: #B3B5C6;
  --gray-300: #9699B0;
  --gray-400: #b8bbd8;
  --gray-500: #616480;
  --gray-600: #4B4D63;
  --gray-700: #353646;
  --gray-800: #1f2029;
  --gray-900: #181B23;

  --red-500: #EF4444;
  --green-500: #10B981;
  --blue-500: #3B82F6;
  --yellow-500: #F59E0B;

}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@media(max-width: 1080px){
  html{
    font-size: 93.75%;
  }
}

@media(max-width: 720px){
  html{
    font-size: 87.5%;
  }
}

body {
  background-color:#E1E0E0;
  color: black;
  font-family: 'Montserrat', sans-serif;
  padding: 0rem 1rem;
  @media(max-width: 720px){
    padding:0rem;
  }
}

body, input, textarea, button {
  color: var(--gray-50);
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

.activeLink {
  color: var(--red-500);
}

.react-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-modal-content {
  width: 100%;
  max-width: 676px;
  background: white;
  padding: 4rem;
  position: relative;
  border-radius: 24px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 720px) {
    margin: 0rem;
    max-width: 95%; 
    background: white;
    overflow: hidden;
    padding: 50px 30px;
  }
}
.react-modal-loja {
  width: 100%;
  max-width: 350px;
  background: #F5F5F5;
  opacity: 1;
  position: relative;
  border-radius: 24px;
  @media screen and (max-width: 720px) {
    margin: 0rem;
    max-width: 90%; 
    background: #F5F5F5;
    opacity: 1;
    overflow: hidden;
  }
}
.react-modal-relatorio {
  width: 100%;
  max-width: 450px;
  background: #F5F5F5;
  opacity: 1;
  position: relative;
  border-radius: 24px;
  @media screen and (max-width: 720px) {
    margin: 0rem;
    max-width: 90%; 
    background: #F5F5F5;
    opacity: 1;
    overflow: hidden;
  }
}
.react-modal-content-produto {
  width: 100%;
  max-width: 826px;
  background: white;
  padding: 6.5rem;
  position: relative;
  border-radius: 24px;

  @media screen and (max-width: 720px) {
    margin: 0rem;
    height: auto;
    max-width: 90%; 
    border-radius: 1.10rem;
    padding: initial;
    background: white;
    overflow: hidden;
  }

}

.react-modal-close {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  border: 0;
  background: transparent;
  transition: filter 0.2s ease;
  &:hover {
    filter: brightness(0.8)
  }
}

.react-tabs__tab-list {
  border-color: var(--gray-600) !important;
}

.react-tabs__tab--selected {
  background: var(--gray-800) !important;
  color: var(--gray-50) !important;
  border-color: var(--gray-600) !important;
}

